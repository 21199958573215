<template>
  <div class="popup_sub_container">
    <h2>VIEW / EDIT POSTAL & PRE-PAID SERVICE TRANSACTIONS</h2>
    <i class="closeme" @click="closeModal">X</i>
    <section  v-if="transaction_id" >
      <div class="jobs_table sp">
      <div class>
        <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
          <section>
            <div class="form_section1">
              <table>
                <tr>
                </tr>
                <td>
                 
                  <div class="label_and_element_wrapper">
                    <label>
                    Requester Name
                     
                    </label>
                   
                     <input type="text"  :disabled="!is_editable" placeholder="Enter Requester Name" v-model="transaction.requester_name" @focus="show_user_name_suggestion = true" @blur="hideSuggestions" autocomplete="new-password" :maxlength="max" />
                      <div class="dynamic_data_wrapper" v-if="show_user_name_suggestion" >
                        <span 
                          v-for="(user,i) in filteredUser" 
                          :key="'delivery_address_'+i"
                          @click="populateUserName(user)"

                          >
                          {{user.name}}
                        </span>
                  </div>
                </div>
                
                </td>

                <td>
                 
                  <div class="label_and_element_wrapper">
                    <label>
                   Requester Cost Centre 
                    
                    </label>
                
                     <select v-model="transaction.cost_center_id" :disabled="!is_editable"  >
                    <option value="" disabled>Requester Cost Centre </option>
                    <option v-for="(costcenter,i) in costcenters" :key="'costcenter'+i" :value="costcenter.id">{{costcenter.name}}</option>
                  </select>
                  </div>
                 
                </td>

                     <tr>

                         <td>
                    <ValidationProvider name="service" rules="required" v-slot="{ errors }" slim>
                      <div class="label_and_element_wrapper">
                      <label>
                      Select Carrier
                      <span>*</span>
                      </label>
                      <select v-model="transaction.provider_id"  @change="fetchPostalDocument" :disabled="!is_editable" :class="errors[0] ? 'warning-border':''">
                      <option value="">Select</option>
                      <option v-for="(carrier,i) in carriers" :key="'carrier'+i" :value="carrier.id">{{carrier.name}}</option>
                      </select>
                       </div>
                    </ValidationProvider>
                   </td>


                <td>
                    <ValidationProvider name="postaldocuments" rules="required" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                      <label>
                        Select Postal Document Type
                        <span>*</span>
                      </label>
                      <select v-model="transaction.postal_document_type_id" :disabled="!is_editable" :class="errors[0] ? 'warning-border':''" @change="changePostaldocument">
                        <option value="">Select</option>
                        <option v-for="(postaldocument,i) in postaldocuments" :key="'postaldocument'+i" :value="postaldocument.id">{{postaldocument.postal_document_name}}</option>
                      </select>
                    </div>
                    </ValidationProvider>
                  </td>

               

<!-- 
                       <td>
                   <ValidationProvider name="service" rules="required" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                    <label>
                      Select Carrier Service Name
                      <span>*</span>
                    </label>
                   <select v-model="transaction.service_providers_id"  :disabled="!is_editable" :class="errors[0] ? 'warning-border':''">
                      <option value="">Select</option>
                      <option v-for="(service,i) in services" :key="'service'+i" :value="service.id">{{service.name}}</option>
                      </select>
                  </div>
                  </ValidationProvider>
                </td> -->
              
                </tr>

          

                <tr>
                <!--   <td>
                  <ValidationProvider name="type" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                       Entry Type
                      <span>*</span>
                    </label>
                    <select v-model="transaction.entry_type" :class="errors[0] ? 'warning-border':''" :disabled="!is_editable" >
                     <option value = "outbound" >Outbound</option>
                      <option value = "inbound" >Inbound</option> 
                    </select>
                  </div>
                  </ValidationProvider>
                </td> -->

                <td>
                <div class="label_and_element_wrapper">
                <label>
                Entered Date
                <span>*</span>
                </label>
                  <flat-pickr
                    class="date_for_pos_fixed"
                    v-model="transaction.entered_date "
                    :config="configDate"
                    placeholder="Date"
                    name="birthdate"
                    :disabled="!is_editable" 
                  ></flat-pickr>
                </div>
                </td>

                           <td>
                  <ValidationProvider name="weight" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                     Weight
                      <span>*</span>
                    </label>
                    <input type="text" placeholder=" Enter Weight" v-model="transaction.weight" :class="errors[0] ? 'warning-border':''" :maxlength="max" :disabled="!is_editable" />
                  </div>
                  </ValidationProvider>
                </td>
                </tr>

                 <tr>
              
                  <td>
                  <ValidationProvider name="postalcount" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                    Postal Count
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Postal Count" v-model="transaction.count" :class="errors[0] ? 'warning-border':''"  :maxlength="max" :disabled="!is_editable" @change="updateCount($event)" />
                  </div>
                  </ValidationProvider>
                </td>
                
                
              
                  <td>
                <!--   <ValidationProvider name="postalamount" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Amount
                      
                    </label>
                    <input type="text" placeholder="Enter Amount" v-model="transaction.postal_amount" :maxlength="max" :disabled="!is_editable" />
                  </div>
                 <!--  </ValidationProvider> -->
                </td>


              </tr>
              <tr>
                   <td>
                 <!--  <ValidationProvider name="comment" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                     Comment
                      
                    </label>
                    <input type="text" placeholder=" Enter Comment" v-model="transaction.comment "  :disabled="!is_editable" :maxlength="max" style="width: 227%;"/>
                  </div>
                  <!-- </ValidationProvider> -->
                </td>
               
             
             </tr>
            

              </table>
            </div>
          </section>
        </ValidationObserver>

          <section class="centered">
            <a class="link_bt bt_cancel" @click="closeModal">CANCEL</a>
            <a class="link_bt bt_save" @click="updateTransaction" :disabled="update_btn.disabled" v-if="is_editable">{{update_btn.value}}</a>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from 'moment';
export default {
  name: "EditBranch",
    components: {
    flatPickr,
  },
  computed:{
  
     filteredUser(){
      return this.user_list.filter(element => {
        if(element.name.toLowerCase().includes(this.transaction.requester_name.toLowerCase())){
          return element;
        }
      })
    }
  },
  created(){
    this.fetchUnit();
    this.fetchUser();
    this.fetchCostCenter();
    this.fetchCarriers();
    //this.fetchPostalDocument();
    
    
   
  },
  data() {
    return {
      max:30,
      max2:60,
      max1:10,
      max3:20,
       configDate: {
        format: "d-m-Y",
        altFormat: "d-m-Y",
        altInput: true
      },
      show_user_name_suggestion:false,
      transaction:{
        provider_id:"",
        service_providers_id:"",
        entry_type:"",
        entered_date:"",
        postal_document_type_id:"",
        count:"",
        comment:"",
        weight:"",
        postal_amount:"",
        requester_name:"",
        cost_center_id:"",
      },
      postaldocuments:[],
      carriers:[],
      services:[],
      costcenters:[],
      user_list:[],
      update_btn:{
        disabled:false,
        value:"UPDATE"
      }
    };
  },
  methods:{
    closeModal() {
      this.$emit("closeModal");
    },

      fetchUser(){
      this.axios.post("api/user/list")
        .then(response => {
          this.user_list = response.data.msg;
        })
        .catch(error => {
          console.log(error);
        });
    },

     hideSuggestions(){
      setTimeout(() => {
        this.show_user_name_suggestion = false;
      },200);
    },
     populateUserName(user){
 
      this.transaction.requester_name = user.name;
      this.transaction.cost_center_id =user.cost.id;
  
      },

    fetchUnit(){
      this.axios.get("api/transaction/view/transactionId/"+this.transaction_id)
      .then(response => {
        this.transaction = response.data.services;
        if(this.transaction.provider_id){
         this.axios.get("/api/postaldocument/carrier/"+this.transaction.provider_id)
        .then(response => {
         this.postaldocuments = response.data.postal_document_types; 

          })
       
        .catch(error => {
          console.log(error);
        });
      }

      })

    },

// to get carrier 
    fetchCarriers(){
      this.axios.get("/api/provider/carrierlist")
      .then(response => {
          this.carriers = response.data.providers; 
          this.fetchPostalDocument();  
      })
      .catch(error =>{
          this.toast.error();
      });
    },
// to get carrier service
    // fetchServices() {
    //   if(this.transaction.provider_id){
    //   this.axios.get("/api/provider/carrier/"+this.transaction.provider_id)
    //     .then(response => {
    //       this.services = response.data.provider.servicenames;     
    //        if(this.services.length > 0 ){
    //           this.transaction.service_providers_id = this.services[0].id;
    //         }
           
    //       })
       
    //     .catch(error => {
    //       console.log(error);
    //     });
    //   }
    // },

      fetchCostCenter()
    {
      this.axios.get("/api/cost/")
      .then(response =>{
        this.costcenters =response.data.cost_centers;
      })
    },

    // fetchServices() {
    //   if(this.transaction.provider_id){
    //   this.axios.get("/api/provider/carrier/"+this.transaction.provider_id)
    //     .then(response => {
    //       this.services = response.data.provider.servicenames;     
    //        if(this.services.length > 0 ){
    //         if(this.transaction.provider_id == "8e00bfb6b2294c95a0808f8327dd82dd")
    //           this.transaction.service_providers_id = "ac0fca91948d476cbf14871c2a8a35f5" ; 
    //         else
    //           this.transaction.service_providers_id = this.services[0].id;
    //         }
           
    //       })
       
    //     .catch(error => {
    //       console.log(error);
    //     });
    //   }
    // },
      // to get postal document type
    fetchPostalDocument(){
      
console.log(this.transaction.provider_id);
console.log('jo');
        if(this.transaction.provider_id){
      this.axios.get("/api/postaldocument/carrier/"+this.transaction.provider_id)
      .then(response => {
          this.postaldocuments = response.data.postal_document_types;
          this.transaction.postal_document_type_id =this.postaldocuments[0].id;
           this.fncalculateamount(this.postaldocuments[0]);
         
          
      })
      .catch(error =>{
          this.toast.error();
      });
    }

    },
     updateCount(event) {
   
    this.transaction.count= event.target.value;
     // this.transaction.postal_amount =  this.transaction.weight * this.transaction.count;
    this.changePostaldocument();
    },
    

     fncalculateamount(postaldocuments){

 this.transaction.postal_amount =postaldocuments.markup_margin_type == 'per' ? (((postaldocuments.markup_margin*1)*parseFloat(postaldocuments.unit_price))/100)*this.transaction.count :(parseFloat(postaldocuments.markup_margin) +parseFloat(postaldocuments.unit_price))*this.transaction.count;

    },
    
     changePostaldocument()
    {
  
    this.axios.get("/api/postaldocument/carrier/"+this.transaction.provider_id)
      .then(response => {
          this.postaldocuments = response.data.postal_document_types;
                let i = 0;
             this.postaldocuments.forEach(item => {
          

             if(this.transaction.postal_document_type_id==item.id)            
            {

            this.fncalculateamount(item); 
            }
          i++;

      });
               
      })
      .catch(error =>{
          this.toast.error();
      });
    },


   // updateWeight(event) {
   
   //  this.transaction.weight= event.target.value;
   //   this.transaction.postal_amount =  this.transaction.weight * this.transaction.count;
    
   //  },

    updateTransaction(){
      this.$refs.observer.validate();
      this.update_btn.disabled = true;
      this.update_btn.value="UPDATING...";
      this.axios.put("api/transaction/"+this.transaction_id,this.transaction).then(response => {
        this.toast.success(response.data.msg);
        this.update_btn.disabled = false;
        this.update_btn.value="UPDATE";
        this.closeModal();
      })
      .catch(error => {
        this.update_btn.disabled = false;
        this.update_btn.value="UPDATE";
        console.log(error);
      });
    }
  },
  props:{
    transaction_id:String,
    is_editable:{
      type:Boolean,
      default:true
    }
  }
};
</script>
<style lang="scss">
.popup_sub_container {
  padding: 20px;
  width: 100%;
  margin: auto;

  background: #fff;
}
.popup_sub_container > section {
  padding: 20px 0 0 0;
}

h3.sub_head {
  font-size: 20px;
  font-weight: 600;
  padding: 0;
}
.preferred_hide {
  display: none;
}
.chk_prefered {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 0 20px;
}
.chk_prefered:checked + hr + .preferred_hide {
  display: block;
}

.title_image {
  display: block;
  margin: auto;
}

.form_section1 {
  width: 100%;
  height: auto;
  padding: 20px;
  background: #ececec;
  margin: 20px 0;
}

i.closeme {
  width: 30px;
  height: 30px;
  background: #000;
  color: #06A5ED;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  border-radius: 100px;
  display: block;
  font-style: normal;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
</style>
<style scoped>
.jobs_table table {
  border: 0;
}
.jobs_table table tr td {
  padding: 0;
  border: 0;
}
</style>